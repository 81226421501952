@import '../styles/_Variables.scss';
@import '../styles/_Fonts.scss';

/* Nav Styles */
.__logo {
    margin-top: 0;
    margin-left: 0.3em;
    width: 3em;
    transition: 0.2s ease-out;

    @media (max-width: $medium-screen-size) {
        margin-left: 0.2em;
    }
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.2em;
    line-height: 0;
    margin-bottom: 0;
    cursor: pointer;
}

.navbar-custom {
    background-color: $white;
    padding: 0;
    flex-direction: column;
    justify-content: space-between; /* This ensures space between logo and nav items */
    align-items: center; /* Vertically center the logo and nav items */

    .navbar-container {
        width: 100%;
        height: 2.5em;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar-visible {
            flex-direction: row;
            justify-content: flex-end; /* Align nav items to the right */

            .nav-item, .dropdown {
                display: flex;
            }
            .nav-item {
                .nav-link {
                    @extend %text-bold;
                    padding: 0 1.2em;
                    font-size: 0.7em;
                    text-transform: uppercase;
                    cursor: pointer;
                    color: #111;
                    border: 0;
                    box-shadow: none;
                    align-self: center;
                    background-color: transparent;

                    &:hover, &.active {
                        color: $orange;
                    }

                    &.btn {
                        line-height: 1.5;
                    }
                }

                @media (max-width: $medium-screen-size) {
                    font-size: 1.1em;
                }
            }

            .dropdown {
                .dropdown-arrow {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    z-index: 1;

                    &:before, &:after {
                        $arrow-size: 15px;

                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        border-bottom: $arrow-size solid transparent;
                        border-left: $arrow-size solid transparent;
                        border-right: $arrow-size solid transparent;
                        margin: auto;
                    }

                    &:before {
                        border-bottom-color: $orange;
                    }

                    &:after {
                        margin-top: -12px;
                        border-bottom-color: $white;
                    }
                }

                .dropdown-menu {
                    min-width: fit-content;
                    max-width: 80vw;
                    border: 1px solid #e1e1e1;
                    border-top: 0;
                    border-radius: 0;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
                    background-clip: unset;
                    overflow-x: auto;

                    .dropdown-container {
                        overflow-y: auto;
                        min-width: 12em;
                        max-height: 50vh;

                        .dropdown-item:active {
                            background-color: $orange;
                            color: $white;
                        }

                        .dropdown-level-1 {
                            padding-left: 10px;
                        }

                        .dropdown-level-2 {
                            padding-left: 20px;
                        }

                        .dropdown-header {
                            text-transform: uppercase;
                            font-size: 0.7em;
                        }

                        .dropdown-header, .dropdown-item {
                            padding-top: 0.4em;
                            padding-bottom: 0.4em;
                        }
                    }
                }
            }
        }
    }

    .navbar-border-container {
        max-width: 100%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        transition: 0.2s ease-out;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .navbar-border {
        border-bottom: 2px solid $orange;
        width: 100%;
    }

    &.full-width {

        .__logo {
            width: 2.5em;
        }

        .navbar-border-container {
            max-width: 100%;
            padding: 0;
        }
    }
}


